// ------------------------------------
//
// Parallax Particles
//
// ------------------------------------

var particles = new Rellax('.ex-particle', {
	speed: -2,
	center: true,
	wrapper: null,
	round: true,
	vertical: true,
	horizontal: false,
	// breakpoints: [576, 768, 1201],
});
