/// ------------------------------------
//
// Turning Timetable
//
// ------------------------------------

var $turns = $('.ex-turn');

$turns.on('click', function () {
	$turns.not(this).removeClass('is-turned');
	$(this).toggleClass('is-turned');
});
