// -------------------------------------
//
// Disclose sections
//
// -------------------------------------
var discloses = document.querySelectorAll('.js-disclose');
Array.prototype.forEach.call(discloses, function (disclose) {
	var toggle = disclose.querySelector('.js-disclose__toggle');
	var disclosePanel = disclose.querySelector('.js-disclose__panel');
	var discloseInner = disclose.querySelector('.js-disclose__inner'); // CSS Custom Property that is updated with the disclose's content's height

	var maxHeightKey = '--disclose-max-height';
	var state = {
		toggleExpanded: 'collapsed',
	};

	// Sets the relevant aria role based on its current value.
	// Sets it to false by default if no value is set

	var setToggleState = function setToggleState() {
		var currentExpandedState = toggle.getAttribute('aria-expanded'); // If it's not set yet, set it to true so it is reversed below

		if (currentExpandedState === null) {
			currentExpandedState = 'true';
		} // Update our global state so other functions know what's up

		state.toggleExpanded = currentExpandedState === 'true' ? 'false' : 'true';
		toggle.setAttribute('aria-expanded', state.toggleExpanded);
		toggle.removeAttribute('hidden');
	};

	// Looks for content within the disclose and calculates its height.
	// This makes the transition smoother.
	// Returns a promise so it can do its job before the toggle starts

	var setMaxHeight = function setMaxHeight() {
		return new Promise(function (resolve, reject) {
			if (!disclosePanel || !discloseInner) {
				reject();
			}

			disclosePanel.style.setProperty(
				maxHeightKey,
				''.concat(discloseInner.getBoundingClientRect().height, 'px')
			);
			resolve();
		});
	};

	var run = function run() {
		setMaxHeight().then(function () {
			return setToggleState();
		});
	};

	toggle.addEventListener('click', run);
	run();
});
