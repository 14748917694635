// ------------------------------------
//
// Flipping Flan
//
// ------------------------------------

var $flips = $('.ex-flip');

$flips.on('click', function () {
	$flips.not(this).removeClass('is-flipped');
	$(this).toggleClass('is-flipped');
});
