// ------------------------------------
//
// Magnifying Minutia
//
// ------------------------------------

var $magnifies = $('.ex-magnify');

$magnifies.on('click', function () {
	$magnifies.not(this).removeClass('is-magnified');
	$(this).toggleClass('is-magnified');
});

// $('.ex-cutout').each(function () {
// 	var elemHeight = $(this).height();
// 	elemHeight = Math.floor(elemHeight);
// 	$(this).height(elemHeight);
// });
