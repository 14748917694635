// ------------------------------------
//
// Spinning spiders (and other creep)
//
// ------------------------------------

var $spins = $('.ex-spinner');

$spins.on('click', function () {
	$spins.not(this).removeClass('is-spun');
	$(this).toggleClass('is-spun');
});
