// ------------------------------------
//
// Pullout Paper
//
// ------------------------------------

var $pullouts = $('.ex-pullout');

$pullouts.on('click', function () {
	$pullouts.not(this).removeClass('is-pulled-out');
	$(this).toggleClass('is-pulled-out');
});
