// ------------------------------------
//
// Hinged Leaves
//
// ------------------------------------

var $hinges = $('.ex-hinge');

$hinges.on('click', function () {
	$hinges.not(this).removeClass('is-open');
	$(this).toggleClass('is-open');
});
